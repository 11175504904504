.ErrorModal {
    padding: 2rem 2.5rem !important;

    .Header {
        height: 60px;
    }

    .Title {
        font-family: 'Noto Sans';
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: #262626;
        width: calc(100% - 36px);
    }

    .Paragraph {
        color:#262626;
        font-family: 'Noto Sans';
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
}
