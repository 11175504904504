.EventBadges {
  .NationwideBadge {
    border: 1px solid #E0E0E0;
    //width: 160px;
  }

  .RegisteredLabel {
    width: 120px;
  }

  .VenueTypeLabel {
    width: 100px;
  }
  .SeriesBadge {
    border: 1px solid #E0E0E0;
    width: 90px;
  }
}