.EventLocation {
  .Text {
    font-size: 1rem !important;
  }

  .DirectionSignIcon {
    width: 24px;
  }

  .HeadsetsIcon, .MapLocationIcon {
    width: 24px;
  }

  .SignInBadge {
    background-color: #FFDD9B;
    border-radius: 4px;
    font-size: 14px;
    font-family: "Noto Sans";
  }
}