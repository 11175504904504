$PrimaryBlue: #004E9C;
$BorderRadius: 8px;

.ButtonPrimary {
    background-color: #e57200 !important;
    color: #ffffff;
    box-shadow: none !important;
    border: 1px solid #e57200 !important;
    border-radius: $BorderRadius !important;
    padding: 0.5rem;
    cursor: pointer;
    font-family: 'Noto Sans';

    &:disabled {
        background-color: #f5f5f5 !important;
        color: #79747e !important;
        border: 1px solid #79747e !important;
        cursor: not-allowed;
    }

    &:hover {
        background-color: #f49b45 !important;
    }

    &:focus,
    &:active {
        background-color: #ffffff !important;
        color: #e57200 !important;
    }
}

.ButtonPrimaryBlue {
    background-color: $PrimaryBlue !important;
    color: #ffffff;
    box-shadow: none !important;
    border: 2px solid $PrimaryBlue !important;
    border-radius: $BorderRadius !important;
    -webkit-border-radius: $BorderRadius !important;
    -moz-border-radius: $BorderRadius !important;
    -ms-border-radius: $BorderRadius !important;
    -o-border-radius: $BorderRadius !important;
    padding: 0.75rem 1.5rem !important;
    cursor: pointer;
    font-family: 'Noto Sans';
    font-weight: 600 !important;
    &:disabled {
        background-color: #E0E0E0 !important;
        color: #79747e !important;
        border: 1px solid #E0E0E0 !important;
        cursor: not-allowed;
    }

    &:hover {
        background-color: #4b78b0 !important;
    }

    // &:focus,
    // &:active {
    //     background-color: #ffffff !important;
    //     color: $PrimaryBlue !important;
    // }
}

.ButtonPrimaryBlue1 {
  background-color: #00366B !important;
  color: #FFFFFF;
  box-shadow: none !important;
  border: 1px solid #00366B !important;
  padding: 0.5rem;
  cursor: pointer;
  font-family: "Noto Sans";
  border-radius: $BorderRadius !important;

  &:disabled {
    background-color: #F5F5F5 !important;
    color: #79747E !important;
    border: 1px solid #79747E !important;
    cursor: not-allowed;
  }

    &:hover {
        background-color: #4b78b0 !important;
    }

  &:focus, &:active {
    background-color: #FFFFFF !important;
    color: #00366B !important;
  }
}

.ButtonLight {
    background-color: #ffffff;
    color: #79747e;
    box-shadow: none !important;
    border: 1px solid #79747e !important;
    border-radius: $BorderRadius !important;
    padding: 0.5rem;
    cursor: pointer;
    font-family: 'Noto Sans';

    &:hover {
        background-color: #f1ebeb !important;
        color: #000000 !important;
    }

    &:focus,
    &:focus-visible,
    &:active {
        background-color: #79747e !important;
        color: #ffffff;
    }
}

.ButtonPrimaryBlueOutline {
  background-color: #ffffff !important;
  color: $PrimaryBlue !important;
  box-shadow: none !important;
  border: 1.4px solid $PrimaryBlue !important;
  border-radius: $BorderRadius !important;
  padding: 0.75rem 1.5rem !important;
  cursor: pointer;
  font-family: 'Noto Sans';
  font-weight: 600 !important;
  &:disabled {
    background-color: #E0E0E0 !important;
    color: #8D8D8D !important;
    border: 0 !important;
    cursor: not-allowed;
  }
  &:hover{
    color: #fff !important;
    background: $PrimaryBlue !important;
  }
}

.LinkButton{
  background-color: transparent !important;
  color: $PrimaryBlue !important;
  box-shadow: none !important;
  border: 0 !important;
  padding: 0.75rem 1.5rem !important;
  cursor: pointer;
  font-family: 'Noto Sans';
  font-weight: 600 !important;
  &:disabled {
    background-color: #E0E0E0 !important;
    color: #8D8D8D !important;
    border: 0 !important;
    cursor: not-allowed;
  }

  &:hover {
    color: #4b78b0 !important;
  }
}

.ButtonLightBlue {
    background-color: #FFFFFF !important;
    color: #004E9C !important;
    box-shadow: none !important;
    border: 2px solid #004E9C !important;
    border-radius: $BorderRadius !important;
    padding: 0.5rem;
    cursor: pointer;
    font-family: 'Noto Sans';
    font-weight: 600 !important;
    &:hover {
        background-color: #004E9C !important;
        color: #EEF3F8 !important;
    }

    &:focus,
    &:focus-visible,
    &:active {
        background-color: #004E9C !important;
        color: #EEF3F8 !important;
    }
}

.ButtonDarkOutlineBlue {
    background-color: #ffffff !important;
    color: #00366b !important;
    box-shadow: none !important;
    border: 2px solid #00366b !important;
    border-radius: $BorderRadius !important;
    padding: 0.5rem;
    cursor: pointer;
    font-family: 'Noto Sans';
    font-weight: 600 !important;

    &:hover {
        background-color: #f1ebeb !important;
        color: #00366b !important;
    }

    &:focus-visible,
    &:active {
        background-color: #00366b !important;
        color: #ffffff !important;
    }
}

.ButtonLightPurple {
    background-color: #E5EDF4 !important;
    color: #004E9C !important;
    box-shadow: none !important;
    border: 2px solid #004E9C !important;
    border-radius: $BorderRadius !important;
    padding: 0.5rem;
    cursor: pointer;
    font-family: 'Noto Sans';
    font-weight: 700;

    &:hover {
        background-color: #004E9C !important;
        color: #ffffff !important;
    }

    &:focus,
    &:focus-visible,
    &:active {
        background-color: #004E9C !important;
        color: #ffffff !important;
    }
}

.ButtonLightGray {
  background-color: #FFFFFF !important;
  color: black !important;
  box-shadow: none !important;
  border: 1px solid rgba(158, 158, 158, 0.5) !important;
  border-radius: $BorderRadius !important;
  padding: 0.5rem;
  cursor: pointer;
  font-family: "Noto Sans";
  transition: border 0.08s ease;

  &:hover {
    color: #00366B !important;
    border: 2px solid #00366B !important;
    transition: border 0.08s ease;
  }

  &:focus, &:focus-visible, &:active {
    color: #00366B !important;
    border: 2px solid #00366B !important;
    transition: border 0.08s ease;
  }
}

.ButtonLightGrayBlue {
  background-color: #FFFFFF !important;
  color: #004E9C !important;
  box-shadow: none !important;
  border: 2px solid #004E9C !important;
  border-radius: $BorderRadius !important;
  padding: 0.5rem;
  cursor: pointer;
  font-family: "Noto Sans";
  transition: border 0.08s ease;

  &:hover {
    color: #FFFBFE !important;
    background-color: #004E9C !important;
    transition: border 0.08s ease;
  }

  &:focus-visible, &:active {
    color: #FFFBFE !important;
    background-color: #004E9C !important;
    transition: border 0.08s ease;
  }
}

.ButtonDarkOutlineBluePill {
  background-color: #ffffff !important;
  border-radius: $BorderRadius !important;
  border: 2px solid #00366B; 
  display: inline-flex !important;
  padding: 8px 18px;
  align-items: center;
  gap: 8px; 
  color: #00366B;
  font-size: 16px;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; 

  &:hover,
  &:focus,
  &:focus-visible,
  &:active {
      background-color: #00366b !important;
      color: #ffffff !important;
  }
}

.LinkBtn{
  color: var(--primary-primary-dark, #1A4480);
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 162%;
  text-decoration-line: underline;
  border: none;
  padding: 0;
  margin: 0;
  height: auto;
  width: auto;
}