$max-height-modal-body: 400px;

.EventsListModal {
    font-family: 'Noto Sans';

    .ModalContent {
        border-radius: 12px !important;
    }

    .Header {
        border-bottom: rgba(158,158,158,0.5) 1px solid;
    }

    .Title {
        font-size: 1rem;
        font-weight: 600;
    }

    .CloseButton {
        font-size: 0.9rem !important;
        font-weight: 600;
        padding-top: 0.4rem !important;
        padding-bottom: 0.4rem !important;
    }

    .DirectionSignIcon {
        width: 20px;
    }

    .Icon {
        width: 22px;
    }

    .CalendarIcon {
        width: 22px;
        height: 14px;
    }

    .ClockIcon {
        width: 22px;
    }

    .Body {
        .SelectableTitles {
            max-height: $max-height-modal-body;
            overflow: auto;
            border-right: rgba(158,158,158,0.5) 1px solid;

            .Title {
                font-size: 0.9rem !important;
                cursor: pointer;
                font-weight: 600;
                border-radius: 8px;

                &.active {
                    background-color: #eef3f8;
                    cursor: auto;
                    color: #004E9C;
                }
            }
        }

        .SelectedEventInformation {
            max-height: $max-height-modal-body;
            overflow: auto;

            .Title {
                font-size: 1.3rem;
            }

            .Description {
                font-size: 0.9rem !important;
            }

            .Details {
                font-size: 0.9rem !important;
            }

            .Location {
                font-size: 0.9rem !important;
                color: #00366b;
            }

            .RegisterButton {
                font-size: 0.9rem !important;
                padding-top: 0.4rem !important;
                padding-bottom: 0.4rem !important;
            }

            .SignInBadge {
                background-color: #FFDD9B;
                border-radius: 4px;
                font-size: 14px;
                font-family: "Noto Sans";
            }
        }
    }
}
