.ViewDetailsStatus {
  .EventDescription{
    color:#262626;
    font-family: 'Noto Sans';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  .DividerFull {
    height: 1px;
    width: 100%;
    background: rgba(158, 158, 158, 0.50);
  }

  .EventMapContainer {
    border-radius: 12px;
    overflow: hidden;
    background-color: #FFE8BC;

    .CustomInnerMapClass {
      height: 200px;
    }

    .SignInBlock {
      height: 140px;
      font-family: "Noto Sans";

      img {
        width: 16px;
        height: 16px;
      }

      span:nth-of-type(1) {
        font-size: 16px;
        font-weight: 600;
        color: #D45900;
      }

      span:nth-of-type(2) {
        font-size: 14px;

        a {
          color: #41394D;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}