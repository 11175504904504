.FiltersModal {
    font-family: 'Noto Sans';
}

.FiltersSmallModal {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.FiltersModalLargeContent {
    border-radius: 12px !important;
}

.FiltersModalSmallContent {
    position: fixed !important;
    bottom: 0;
    height: 85vh;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.FiltersModalContent {
    .Title {
        font-size: 1.3rem;
        font-weight: 600;
        color: #00366b;
    }

    .Results {
        font-size: 1rem;
        color: #00366b;
    }

    .FiltersModalBody {
        overflow: auto;

        .Subtitle {
            font-size: 1rem;
        }
    }

    .Actions {
        .ClearButton {
            font-size: 1rem;
            color: #00366b;
            font-weight: 600;
            cursor: pointer;
            font-weight: normal !important;
            width: 45%;
        }

        .SubmitButton {
            font-weight: normal;
            width: 45%;
        }
    }
}

.MobileFilterBadge {
    font-family: 'Noto Sans';
    font-size: 0.9rem;
    font-weight: 600;
    color: #00366b;
    border-radius: 8px;
    background-color: #fffbfe;
    border: 1px solid rgba(158, 158, 158, 0.5) !important;
    cursor: pointer;
    height: 44.8px;

    span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.Active {
    background-color: #00366b;
    color: #fffbfe;
    border: 1px solid #00366b !important;
}

.Disabled {
    background-color: #f5f5f5;
    color: #00366b;
    border: 1px solid #9e9e9e80 !important;
}
